import React, { useEffect, useState } from "react";
import { navigate } from "gatsby";

import Layout from '../../components/layout/Layout'
import { useIsMobile } from "../../hooks/useIsMobile";
import { getReservationOfTheWeek } from "../../services/ceramique-reservation";
import { HonestWeekPicker } from "../../components/calendrier/WeekPicker";
import moment from "moment";
import { endOfWeek, startOfWeek } from "date-fns";
import CalendarDay from "../../components/calendrier/CalendarDay";
import { ArrowLeft } from "../../icons/ArrowLeft";
import { ArrowRight } from "../../icons/ArrowRight";
import { useReservations } from "../../context/CalendrierContext";


const daysOfWeek = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche', ];

const CalendrierPage = () => {
  moment.locale('fr');

  const { reservations, addReservation, removeReservation, editReservation, updateAllReservations, updateWeek } = useReservations();

  const { isMobile } = useIsMobile()

  // Needed because the auth plugin add a grey overlay to the whole page, and don't delete it after auth :o
  const fixAuthOverlay = () => {
    const overlay = document.querySelector('div[style*="height:100vh"][style*="background:#424242"]');
    if (overlay) {
      overlay.style.height = "auto";  // Supprime la hauteur forcée
      overlay.style.background = "transparent"; // Supprime le fond gris
      overlay.style.display = "block"; // Assure qu'il reste visible normalement
    }
  };

  useEffect(() => {
    setTimeout(fixAuthOverlay, 300);
  }, []);

  const [week, setWeek] = useState({
    firstDay: startOfWeek(new Date(), { weekStartsOn: 1 }),
    lastDay: endOfWeek(new Date(), { weekStartsOn: 1 })
  });

  const onChange = (week) => {
    setWeek(week);
    updateWeek(week)
    getReservationOfTheWeek(week).then((response) => {
      updateAllReservations(response)
    })
  };


  useEffect(() => {
    moment.locale('fr');
  }, [week])

  const minusOneWeek = () => {
    const firstDayOfCurrentWeek = moment(week.firstDay);
    const lastDayOfCurrentWeek = moment(week.lastDay);
    const firstDayOfPreviousWeek = firstDayOfCurrentWeek.clone().subtract(1, 'week');
    const lastDayOfPreviousWeek = lastDayOfCurrentWeek.clone().subtract(1, 'week');

    const newWeek = {
      firstDay: firstDayOfPreviousWeek.toDate(),
      lastDay: lastDayOfPreviousWeek.toDate()
    };
    onChange(newWeek)
  }

  const plusOneWeek = () => {
    const firstDayOfCurrentWeek = moment(week.firstDay);
    const lastDayOfCurrentWeek = moment(week.lastDay);
    const firstDayOfNextWeek = firstDayOfCurrentWeek.clone().add(1, 'week');
    const lastDayOfNextWeek = lastDayOfCurrentWeek.clone().add(0, 'week').add(7, "days");

    const newWeek = {
      firstDay: firstDayOfNextWeek.toDate(),
      lastDay: lastDayOfNextWeek.toDate()
    };
    onChange(newWeek)

  }

  return (
    <Layout>
      <section className="section" style={{backgroundColor : "#ffffff", paddingTop : "0rem"}}>
        <div className="container">
          <div className="content">

            <div className="calendrier-header">

              <div onClick={() => minusOneWeek()} className="arrow-container">
                <ArrowLeft size={"50px"}/>
              </div>
              <HonestWeekPicker onChange={onChange} week={week} setWeek={setWeek} firstDateSelected={week.firstDay} />
              <div onClick={() => plusOneWeek()} className="arrow-container" style={{}}>
                <ArrowRight size={"50px"}/>
              </div>

              {!isMobile && <div className="side-button-absolute">
                <button className="button has-text-terracota">
                  <a href={"/add-news"} className={"has-text-terracota"}>
                    News
                  </a>
                </button>
              </div>
              }

            </div>

            <div className="grid grid-container">
              {daysOfWeek.map((day, index) => {
                let dateOfDay = moment(week.firstDay).add(index, "days")
                if(day != "Lundi" && day != "Dimanche") {
                  return (
                    <div className="day cell" key={"day-cell-" + day}>
                      <div className="day-header" key={"day-header-" + day}>
                        {day} {dateOfDay.toDate().getDate()}
                      </div>
                      <CalendarDay reservations={reservations[day]} dayOfWeek={day} day={dateOfDay} key={"calendar-day-" + daysOfWeek} />
                    </div>
                  )
                }
              })
              }
            </div>

            {isMobile && <div className="side-button-absolute">
              <button className="button has-text-terracota" style={{marginTop : "0.3rem"}}>
                <a href={"/add-news"} className={"has-text-terracota"}>
                  News
                </a>
              </button>
            </div>
            }

          </div>
        </div>
      </section>
    </Layout >
  )}


export default CalendrierPage
